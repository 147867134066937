import React, { useState } from 'react';

const AppBanner: React.FC = () => {
  const [visible, setVisible] = useState(true);

  if (!visible) {
    return null; // Don't render the banner if it's dismissed
  }

  return (
    <div
      style={{
        backgroundColor: '#FFD700',
        color: '#000',
        padding: '10px',
        textAlign: 'center',
        position: 'fixed',
        top: 0,
        width: '100%',
        zIndex: 1000,
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
      }}
    >
      <strong>🆕 3RM Announcement:</strong> 3RM has launched a new AI platform for Telegram + Google Calendar. Your data
      remains safe and you can request access at any time but all development on 3RM features have been suspended.{' '}
      <a
        href="https://www.3rm.co/update"
        target="_blank"
        rel="noopener noreferrer"
        style={{
          color: '#007BFF',
          textDecoration: 'underline',
          fontWeight: 'bold',
        }}
      >
        Read more here
      </a>
      <button
        type="button"
        onClick={() => setVisible(false)}
        style={{
          marginLeft: '15px',
          background: 'none',
          border: 'none',
          fontSize: '16px',
          color: '#000',
          cursor: 'pointer',
        }}
      >
        ✖
      </button>
    </div>
  );
};

export default AppBanner;
